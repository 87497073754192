import React from 'react'
import { Link } from 'gatsby'
import { Seo } from '../components'

const BuyAgain = () => {
  return (
    <>
      <Seo title="Buy Again" />
      <h1>Buy Again Page</h1>
      <Link to="/">Go back to the homepage</Link>
    </>
  )
}

export default BuyAgain
